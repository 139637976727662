<template>
  <v-main>
    <v-container
      fluid
      fill-height
    >
      <v-layout
        align-center
        justify-center
      >
        <v-flex
          xs12
          sm8
          md4
        >
          <v-card class="elevation-12">
            <v-toolbar
              color="#4D3758"
              dark
              flat
            >
              <v-toolbar-title>Connexion</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-alert
                text
                color="red"
                type="error"
                v-show="(error !== null)"
              >{{ error }}</v-alert>
              <v-form>
                <v-text-field
                  label="Login"
                  v-model="username"
                  name="login"
                  prepend-icon="person"
                  type="text"
                ></v-text-field>

                <v-text-field
                  id="password"
                  v-model="password"
                  label="Password"
                  name="password"
                  prepend-icon="lock"
                  type="password"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="validate()">Login</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      error: null,
      password: null,
      username: null
    }
  },
  mounted () {
    const token = localStorage.getItem('tokenData')
    if (token !== null) {
      this.$router.push('/')
    }
  },
  methods: {
    validate: function () {
      axios.post(process.env.VUE_APP_ROOT_API + '/auth/login', {
        username: this.username,
        password: this.password
      }).then(response => {
        console.log('[+] Connected to API')
        const expiresIn = new Date((new Date()).getTime() + response.data.expiresIn).getTime()
        this.$parent.$parent.$parent.setToken(response.data.token, expiresIn)
        setTimeout(this.$parent.$parent.$parent.renewToken(), (response.data.expiresIn / 2) * 1000)
        this.$router.push('/')
      }).catch(error => {
        if (error.response !== undefined && error.response.data.message !== undefined) {
          this.error = error.response.data.message
        } else {
          this.error = error
        }
      })
    }
  }
}
</script>
